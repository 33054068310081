import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './App.css';

const images = [
  "https://fuchsia-acute-roundworm-914.mypinata.cloud/ipfs/QmeJjJHn2GwZSnyHE4dveoA1yiHP8KBGXoLcfv4UTehSZb",
  "https://fuchsia-acute-roundworm-914.mypinata.cloud/ipfs/QmcgaAsvoDCMzxKzHY3r3uTTK6YsoR1ZSuAo3qa5tmcC5G",
  "https://fuchsia-acute-roundworm-914.mypinata.cloud/ipfs/QmbWoAR8TEShg1qDU86reAQbEHV9KbCvtBiDP5duyhx9rx",
  "https://fuchsia-acute-roundworm-914.mypinata.cloud/ipfs/QmfLEVAk9YFXcfPeH4DkMeJzJQrK9BertA4682wFPo1f5x",
  "https://fuchsia-acute-roundworm-914.mypinata.cloud/ipfs/QmdW1NGJ2uz5u46PnUmwKCgsFXrEqk9KjfmFUqftMSDGKi",
  "https://fuchsia-acute-roundworm-914.mypinata.cloud/ipfs/QmbCZ9H5UUTwtuj8f7u1iFMzP3Uk3DEDwUVnHW5MGXX1cp",
  // 添加更多图片链接...
];

const App = () => {
  return (
    <div className="App">
      <nav className="App-nav">
        Slerf AI Artist X/Twitter: <a href="https://twitter.com/Slerf_Ai_Sol" className="App-twitter-link">@Slerf_Ai_Sol</a>
      </nav>
      <header className="App-header">
        Slerf AI Artist
      </header>
      <div className="App-narrative">
        <p>As an unknown $Slerf Holder, super fan, and of course - lazy as hell artist, I decided on a particularly boring afternoon (probably like any other) to channel my sloth spirit into artistic action. So I moved my fingers, without pre-sale, without any fanfare, quietly minted $SlerfAI tokens, keeping the same amount as $Slerf, and lazily allocated 50% to the liquidity pool - so that everyone can trade anytime, anywhere, 40% is ready to land on the lucky person’s head through airdrop, and the remaining 10%? Well, keep it to myself - as a personal little $Slerf piggy bank.</p>
        <p>Sol Contract Address:</p>
        <p className="App-contract-address"> <span className="highlight">7FsUzap4C7sHTnYBWHUDWFDACx1HgfTH3UsrU3c9a7kT</span></p>
        <p>But don’t rush, take your time, rush is not welcome in the world of sloths.</p>
      </div>
      <div className="Gallery">
        {images.map((image, index) => (
          <div className="Gallery-item" key={index}>
            <LazyLoadImage
              src={image}
              alt={`Slerf NFT ${index + 1}`}
              effect="blur"
              width="100%"
              height="100%"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
